<template>
    <div :id="'benefit-' + benefit.id" :class="['-item', (isCarousel) ? 'slide slide-flex' : '']">
        <div v-if="isHostedBenefit" class="-benefit-number-label d-md-none" v-html="hostedBenefitNumberLabel"></div>
        <div class="-overview">
            <img v-if="benefit.thumbnailImageUrl" :src="benefit.thumbnailImageUrl" width="230" class="d-none d-md-block" :title="benefit.name" :alt="benefit.name" />
            <div v-if="benefit.isEvent" :class="['mt-1 text--small -details', (collapseMobileFields) ? 'mobile-benefit-collapsed' : '']">
                <div v-if="benefit.meals">Meals: <b v-html="benefit.meals"></b></div>
                <div v-if="benefit.ageAppropriateness">Recommended&nbsp;Ages: <b v-html="benefit.ageAppropriateness"></b></div>
                <div v-if="benefit.activityLevel">Activity&nbsp;Level: <b v-html="benefit.activityLevel"></b></div>
                <div v-if="benefit.experiences">Experience(s): <b v-html="benefit.experiences"></b></div>
            </div>
        </div>
        <div class="-description">
            <h3 v-html="benefit.name"></h3>
            <div v-if="benefit.location || benefit.duration" class="mt-1">
                <span v-if="benefit.location" v-html="benefit.location"></span>
                <span v-if="benefit.duration" v-html="benefit.duration"></span>
            </div>
            <div v-if="benefit.notice" class="-notice mt-1" v-html="benefit.notice"></div>
            <div v-if="collapseMobileFields" class="d-md-none mobile-benefit-expand-button mt-1 text-center"><button class="btn btn-xs btn-primary-emphasis" @click="expandHostedBenefits(benefit.id)">Show all details</button></div>
            <div :class="['mt-1', (collapseMobileFields) ? 'mobile-benefit-collapsed' : '']" v-html="benefit.description"></div>
            <div v-if="benefit.isEvent" :class="['mt-1', (collapseMobileFields) ? 'mobile-benefit-collapsed' : '']">
                <div><b v-html="benefit.availability"></b> of {{ benefit.capacity }} spaces remaining {{ benefit.waitlist }}</div>
            </div>
        </div>
        <div v-if="isHostedBenefit" class="-benefit-number-label d-none d-md-block" v-html="hostedBenefitNumberLabel"></div>
    </div>
</template>


<script lang="ts">
    import { CruiseBenefit } from "interfaces/cruise";
    import * as virtuosoSharedHeader from "virtuoso-shared-web-ui";
    import { PropType, defineComponent } from "vue";


    export default defineComponent({
        props: {
            benefit: {
                type: Object as PropType<CruiseBenefit>,
                default: undefined
            },
            hostedBenefitIndex: {
                type: Number,
                default: undefined
            },
            isCarousel: {
                type: Boolean
            },
            isHostedBenefit: {
                type: Boolean
            },
            totalHostedBenefits: {
                type: Number,
                default: undefined
            }
        },

        data() {
            return {
                collapseMobileFields: false,
                hostedBenefitNumberLabel: ""
            };
        },

        mounted: function (): void {
            this.hostedBenefitNumberLabel = `${this.hostedBenefitIndex + 1} of ${this.totalHostedBenefits} possible benefit${virtuosoSharedHeader.getPlural(this.totalHostedBenefits)}`;
            if (this.isHostedBenefit && !this.benefit.isForAllGuests) {
                this.collapseMobileFields = true;
            }
        },

        methods: {
            expandHostedBenefits: function (benefitId: number): void {
                $(".mobile-benefit-collapsed", `#benefit-${benefitId}`).removeClass("mobile-benefit-collapsed");
                $(".mobile-benefit-expand-button", `#benefit-${benefitId}`).remove();
                $(".hosted-benefits-carousel").slick("refresh"); // Need the carousel to recalculate the height

            }
        }

    });
</script>
